import React from "react";
import { graphql } from "gatsby";
import { StructuredText } from "react-datocms";
import {
  renderRule,
  isHeading,
  isParagraph,
  isList,
} from "datocms-structured-text-utils";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

import { useLightboxState } from "@context";

import Paragraph from "@components/Paragraph";
import Title from "@components/Title";
import Layout from "@hoc/Layout";
import Button from "@components/Button";
import Seo from "@components/Seo";
import Link from "@components/Link";
import Icon from "@components/Icon";
import Lightbox from "@components/Lightbox";

import {
  StructuredImage,
  StructuredGallery,
  StructuredLineBreak,
  StructuredImageText,
  StructuredQuote,
  StructuredList,
} from "@components/Structured";
import Divider from "@components/Divider";

const VideoEmbed = ({ background, video, className }) => {
  const { setLightboxImages, setLightboxVideo, setLightboxOpen } =
    useLightboxState();
  const bg = convertToBgImage(getImage(background));

  const handleVideoClick = () => {
    setLightboxImages([]);
    setLightboxVideo(video);
    setLightboxOpen(true);
  };

  return (
    <div
      className={`group w-full pt-[56.25%] relative cursor-pointer ${className}`}
      onClick={handleVideoClick}
    >
      <BackgroundImage
        {...bg}
        className="w-full h-full absolute top-0 left-0"
        style={{ position: "absolute" }}
      >
        <div className="block p-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border border-dashed border-primary rounded-full group-hover:scale-110 origin-center transition-all">
          <span className="block w-20 h-20 bg-black rounded-full">
            <Icon
              {...{
                name: "play",
                className:
                  "text-white w-5 h-5 ml-0.5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
              }}
            />
          </span>
        </div>
      </BackgroundImage>
    </div>
  );
};

export default function CreatorPost({ pageContext, data }) {
  const background = getImage(data.creator.heroImage);
  const bg = convertToBgImage(background);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Layout locale={pageContext.locale}>
      <Seo {...{ ...data.creator.seoMeta, lang: pageContext.locale }} />
      <BackgroundImage Tag="section" {...bg} className="">
        <div className="bg-black opacity-40 h-full w-full absolute top-0 left-0 z-10" />
        <div className="w-11/12 mx-auto h-full flex items-baseline py-28 lg:pt-80 lg:pb-40 flex-col relative z-20">
          <span className="my-6 font-body uppercase text-white tracking-widest">
            {data.creator.meta.firstPublishedAt}
          </span>
          <Title
            {...{
              color: "white",
              type: 1,
              font: "body",
              className:
                "mt-0 font-bold w-full md:w-2/3 text-4xl lg:text-6xl xl:text-7xl",
            }}
          >
            {pageContext.name}
          </Title>
        </div>
        <Divider
          {...{
            name: "texture1",
            className: `absolute transform -scale-y-100 -bottom-1 left-0 z-50`,
            fill: "fill-black",
          }}
        />
      </BackgroundImage>
      <section
        {...{
          className: "relative overflow-hidden",
        }}
      >
        <div className="bg-black text-white py-20 relative">
          <div className="w-11/12 mx-auto grid grid-cols-1 lg:grid-cols-12 lg:gap-12 relative z-30 text-center lg:text-left">
            <div className="col-span-full lg:col-span-3">
              <div className="inline-block p-2 lg:mb-8 border border-dashed border-primary rounded-full">
                {data.creator?.profileImage ? (
                  <GatsbyImage
                    image={getImage(data.creator?.profileImage)}
                    alt={pageContext?.name}
                    className="w-32 md:w-40 rounded-full"
                  />
                ) : null}
              </div>

              {data.creator?.socialMedia?.length > 0 ? (
                <div className="mb-12 lg:mb-0 text-center lg:text-left">
                  <Title
                    {...{
                      type: 2,
                      color: "white",
                      className:
                        "mb-8 !text-3xl !lg:text-3xl !xl:text-4xl lg:mt-0",
                    }}
                  >
                    Follow on <br /> social media
                  </Title>

                  <div className="flex flex-wrap items-center justify-center lg:justify-start max-w-[200px] mx-auto lg:-mx-2 -m-2">
                    {data.creator?.socialMedia?.map(
                      ({ socialMediaType, socialLink }, i) => (
                        <div key={i} className="w-1/3 p-2">
                          <a
                            href={socialLink}
                            className="group block w-4 h-4 mx-auto lg:mx-0 relative"
                          >
                            <Icon
                              name={socialMediaType.toLowerCase()}
                              className="block w-6 h-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fill-white group-hover:fill-primary transition"
                            />
                          </a>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-span-full lg:col-span-7 lg:col-start-4">
              <span className="block w-full relative">
                <VideoEmbed
                  background={data.creator?.videoFallbackImage}
                  video={data.creator?.video}
                />
              </span>
            </div>
          </div>

          <StaticImage
            src="../assets/geolines/bkg-black-lines-left@2x.png"
            className="!absolute left-0 -bottom-10 max-w-[650px] xl:max-w-[725px] 2xl:max-w-[750px] z-20 select-none pointer-events-none"
            width={650}
            alt="Geolines Graphic"
          />
        </div>
        <div className="py-20 pt-28 relative z-30">
          <Divider
            {...{
              name: "texture2",
              className: `z-50 min-w-[850px] absolute top-0 left-0`,
              fill: "fill-black",
            }}
          />
          <div className="pb-20 mb-2 border-b-2 border-offwhite border-opacity-30 border-dashed">
            <StructuredText
              data={data.creator.content}
              renderBlock={({ record }) => {
                switch (record.__typename) {
                  case "DatoCmsStructuredImage":
                    return (
                      <StructuredImage
                        {...{
                          image: record.image,
                          alt: record.image.alt,
                          className: "max-w-screen-lg mx-auto w-11/12 mb-8",
                        }}
                      />
                    );
                  case "DatoCmsArticleLinebreak":
                    return (
                      <StructuredLineBreak
                        {...{
                          className: "max-w-screen-2xl mx-auto w-11/12 mb-8",
                        }}
                      />
                    );
                  case "DatoCmsStructuredGallery":
                    return (
                      <StructuredGallery
                        {...{
                          images: record.images,
                          className: "mb-8",
                        }}
                      />
                    );
                  case "DatoCmsStructuredImageText":
                    return (
                      <StructuredImageText
                        {...{
                          image: record.image,
                          alt: record.image.alt,
                          body: record.body,
                          reverse: record.reverseOrder,
                          className: "max-w-screen-lg mb-8 mx-auto w-11/12",
                        }}
                      />
                    );
                  case "DatoCmsStructuredQuote":
                    return (
                      <StructuredQuote
                        {...{
                          quote: record.quote,
                          className: "max-w-3xl mb-8 mx-auto w-11/12",
                        }}
                      />
                    );
                  default:
                    return null;
                }
              }}
              customRules={[
                renderRule(isHeading, ({ node, children, key }) => {
                  return (
                    <Title
                      {...{
                        key,
                        color: node.level === 1 ? "black" : "primary",
                        type: node.level,
                        className: `max-w-3xl w-11/12 mx-auto`,
                      }}
                    >
                      {children}
                    </Title>
                  );
                }),
                renderRule(isParagraph, ({ node, children, key }) => {
                  return (
                    <Paragraph
                      {...{
                        key,
                        color: "black",
                        className: "mb-8 max-w-3xl mx-auto w-11/12",
                      }}
                    >
                      {children}
                    </Paragraph>
                  );
                }),
                renderRule(isList, ({ node, children, adapter, key }) => {
                  return (
                    <StructuredList
                      {...{
                        node,
                        adapter,
                        key,
                        className: "max-w-3xl w-11/12 mx-auto mb-8",
                      }}
                    >
                      {children}
                    </StructuredList>
                  );
                }),
              ]}
            />

            {/* {pageContext.newsAuthor && (
                            <AuthorCard
                                {...{
                                    name: pageContext.newsAuthor.authorName,
                                    email: pageContext.newsAuthor.authorEmail,
                                    socials: pageContext.newsAuthor.socialMedia,
                                    image: pageContext.newsAuthor.authorImage,
                                    blurb: pageContext.newsAuthor.authorBlurb,
                                    className: 'lg:max-w-screen-lg mx-auto',
                                }}
                            />
                        )} */}
          </div>
          <div className="max-w-screen-xl w-11/12 mx-auto flex flex-col items-center justify-center gap-8 md:flex-row md:justify-between my-8 xl:my-16">
            <Link
              {...{
                to: "/",
                type: "button",
                color: "white",
              }}
            >
              Back Home
            </Link>

            <Button
              {...{
                clickHandler: () => scrollTop(),
                className: "!text-3xl",
              }}
            >
              Back To Top
            </Button>
          </div>
        </div>

        <StaticImage
          src="../assets/geolines/bkg-white-lines-left@2x.png"
          className="!absolute left-0 -bottom-28 max-w-[750px] 2xl:max-w-[950px] z-20 opacity-75"
          width={650}
          alt="Geolines Graphic"
        />

        <StaticImage
          src="../assets/geolines/bkg-white-lines-right@2x.png"
          className="!absolute hidden lg:block right-0 -bottom-28 max-w-[400px] xl:max-w-[500px] z-20 opacity-75"
          width={450}
          alt="Geolines Graphic"
        />
      </section>
      <Lightbox />
    </Layout>
  );
}

export const query = graphql`
  query getAnglerCreator($id: String, $locale: String) {
    creator: datoCmsCreator(id: { eq: $id }, locale: { eq: $locale }) {
      excerpt
      meta {
        firstPublishedAt(formatString: "DD/MM/YYYY")
      }
      seoMeta {
        description
        title
        twitterCard
        image {
          url
        }
      }
      videoFallbackImage {
        gatsbyImageData(
          width: 700
          imgixParams: { fit: "crop" }
          placeholder: DOMINANT_COLOR
        )
        alt
      }
      video {
        height
        provider
        providerUid
        thumbnailUrl
        title
        url
        width
      }
      socialMedia {
        socialMediaType
        socialLink
      }
      heroImage {
        gatsbyImageData(width: 1200, placeholder: BLURRED)
        alt
      }
      profileImage {
        gatsbyImageData(width: 160, placeholder: DOMINANT_COLOR)
        alt
      }
      content {
        value
        blocks {
          ... on DatoCmsArticleLinebreak {
            __typename
            id: originalId
          }
          ... on DatoCmsStructuredImage {
            __typename
            id: originalId
            image {
              gatsbyImageData(width: 900, placeholder: DOMINANT_COLOR)
              alt
              title
            }
          }
          ... on DatoCmsStructuredGallery {
            __typename
            id: originalId
            images {
              gatsbyImageData(width: 500, placeholder: DOMINANT_COLOR)
              full: gatsbyImageData(width: 1200, placeholder: DOMINANT_COLOR)
              alt
            }
          }
          ... on DatoCmsStructuredQuote {
            __typename
            id: originalId
            quote
          }
          ... on DatoCmsStructuredImageText {
            __typename
            id: originalId
            reverseOrder
            body {
              value
            }
            image {
              gatsbyImageData(width: 600, placeholder: DOMINANT_COLOR)
              alt
              title
            }
          }
        }
      }
    }

    creators: allDatoCmsCreator(
      filter: { locale: { eq: $locale }, id: { ne: $id } }
      sort: { order: DESC, fields: meta___createdAt }
      limit: 3
    ) {
      nodes {
        id
        meta {
          firstPublishedAt(formatString: "DD/MM/YYYY")
        }
        heroImage {
          gatsbyImageData(width: 600, placeholder: DOMINANT_COLOR)
        }
        socialMedia {
          socialMediaType
          socialLink
        }
        pageSlug
        name
        excerpt
      }
    }
  }
`;
